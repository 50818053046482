
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface Tab {
    value: number,
    label: string,
    params: number[]
}

@Component({
    selector: 'app-toogle-button',
    templateUrl: './toogle-button.component.html',
    styleUrls: ['./toogle-button.component.scss']
})
export class ToogleButtonComponent {

    @Input() tabs: Tab[];
    @Input() tabSelected: number;
    @Output() public selectedTabChange = new EventEmitter();

    constructor() { }

    changeTab(value: number) {
        this.tabSelected = value;
        this.selectedTabChange.emit(this.tabSelected);
    }


}
