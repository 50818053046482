<div class="modal-body line-debt">
  <div class="header-modal">
    <strong>
      <h3>Detalhes</h3>
    </strong>
    <div class="close-button">
      <a class="close-text" (click)="close()"><span class="close-icon">
          X</span>
        X</a>
    </div>
  </div>


  <hr />

  <div>
    <div class="row mb-4 details-row">
      <div class="col-6">
        <p>Origem:</p>
      </div>
      <div class="col-6">
        <p class="details text-align-right">{{debt.PortfolioName}}</p>
      </div>
    </div>
    <div class="row mb-4 details-row">
      <div class="col-6">
        <p>Produto:</p>
      </div>
      <div class="col-6 ">
        <p class="details text-align-right">{{debt.ProductName}}</p>
      </div>
    </div>
    <!-- <div class="row mb-4 details-row">
      <div class="col-6">
        <p>Detalhe do Produto:</p>
      </div>
      <div class="col-6 ">
        <p class="details text-align-right">{{debt.ProductName}}</p>
      </div>
    </div> -->
    <div class="row mb-4 details-row">
      <div class="col-6">
        <p>Nº do contrato :</p>
      </div>
      <div class="col-6">
        <p class="details text-align-right">{{debt.DebtNumber}}</p>
      </div>
    </div>
    <div class="row mb-4 details-row">
      <div class="col-6">
        <p>Tempo de atraso:</p>
      </div>
      <div class="col-6">
        <p class="details text-align-right">{{delayDebt}}</p>
      </div>
    </div>
    <div class="row mb-4 details-row">
      <div class="col-6">
        <p>Data do atraso:</p>
      </div>
      <div class="col-6">
        <p class="details text-align-right">{{debt.FirstDefaultDate | date: 'dd/MM/yyyy'}}</p>
      </div>
    </div>
    <!-- <div class="row mb-4 details-row">
      <div class="col-6">
        <p>Valor atual :</p>
      </div>
      <div class="col-6">
        <p class="details text-align-right">{{debt.DebtTotalBalance | currency: 'BRL':'R$':'1.2-2'}}</p>
      </div>
    </div>
    <div class="row mb-4 details-row">
      <div class="col-6">
        <p>Valor da oferta:</p>
      </div>
      <div class="col-6">
        <p class="details text-align-right">{{debt.CurrentBalance | currency: 'BRL':'R$':'1.2-2'}}</p>
      </div>
    </div>
    <div class="row mb-4 details-row">
      <div class="col-6">
        <p>Desconto:</p>
      </div>
      <div class="col-6">
        <p class="details text-align-right">{{debt.OfferDiscount}} off</p>
      </div>
    </div> -->
    <div class="row">
      <div class="close-btn" (click)="close()">
        <button (click)="close()">Fechar</button>
      </div>
    </div>
  </div>
</div>