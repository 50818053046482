import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ArrangementService } from 'src/app/services/arrangement.service';
import { ToastService } from 'src/app/services/toast.service';
import { Debt } from '../../models/debt.model';
import { DischargeModalComponent } from '../discharge-modal/discharge-modal.component';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AuthenticationService } from '../../../services/authentication.service';
import { ConfirmPixModalComponent } from '../confirm-payment-modal/confirm-payment-modal.component';
import { PaymentStatusTypes } from '../../models/paymentConfirmation.model';
import { MetaPixelService } from 'src/app/services/meta-pixel.service';

@Component({
  selector: 'app-debt-card',
  templateUrl: './debt-card.component.html',
  styleUrls: ['./debt-card.component.scss'],
})
export class DebtCardComponent implements OnInit {

  public isLoading = false;
  infoDischarge: any;
  stateValue: any;
  customerInfo: any = {};
  public coppied = false;
  @Input() public debt;
  @Input() public isQRCodeVisible: boolean = false;
  @Output() public showBackCard: EventEmitter<number> = new EventEmitter<number>();
  modalPaymentOpened: boolean = false;

  constructor(
    public authenticationService: AuthenticationService,
    private arrangementService: ArrangementService,
    private toastService: ToastService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private modalService: NgbModal,
    private gtmService: GoogleTagManagerService,
    private metaService: MetaPixelService
  ) { }

  ngOnInit(): void {
    this.authenticationService.getCustomerInfo().then((rtn) => {
      this.customerInfo = rtn;
    });

    this.sendEcommerceData();
    this.sendDataPurchase();
    this.sendImpressions();
    this.sendProductInteraction();
  }

  public sendEcommerceData() {
    const ecommerceTag = {
      ecommerce: {
        purchase: {
          actionField: {
            id: this.debt.ArrangementId,
            revenue: this.debt.DebtTotalBalance,
          },
          products: [
            {
              name: this.debt.PortfolioName,
              id: this.debt.DebtId,
              price: this.debt.ArrangementAmount,
            },
          ],
        },
      },
    };
    this.gtmService.pushTag(ecommerceTag);
  }

  trackCart() {
    this.metaService.trackCart();
  }

  public sendDataPurchase() {
    const purchase = {
      event: 'purchase',
      ecommerce: {
        transaction_id: this.debt.BindingId,
        value: this.debt.DebtTotalBalance,
        currency: 'BRL',
        items: [
          {
            item_name: this.debt.PortfolioName,
            item_id: this.debt.BindingId,
            price: this.debt.ArrangementAmount,
            quantity: '1',
          },
        ],
      },
    };
    this.gtmService.pushTag(purchase);
  }

  public sendImpressions() {
    const impressions = {
      ecommerce: {
        currencyCode: 'BRL',
        impressions: [
          {
            name: this.debt.PortfolioName,
            id: this.debt.BindingId,
            price: this.debt.DebtTotalBalance,
            quantity: '1',
            dimensionX: 'desconto/transação​',
          },
        ],
      },
    };
    this.gtmService.pushTag(impressions);
  }

  public sendProductInteraction() {
    const interaction = {
      event: 'view_item',
      ecommerce: {
        transaction_id: this.debt.BindingId,
        currency: 'BRL',
        value: this.debt.DebtTotalBalance,
        items: [
          {
            item_id: this.debt.ArrangementId,
            price: this.debt.ArrangementAmount,
            quantity: '1',
          },
        ],
      },
    };
    this.gtmService.pushTag(interaction);
  }

  getStateValue(ev) {
    this.stateValue = ev;
  }

  openModalDischarge() {
    this.isLoading = true;
    this.arrangementService
      .getDischardById(this.debt.BindingId)
      .toPromise()
      .then((res) => {
        const modalRef = this.modalService.open(DischargeModalComponent, {
          size: 'lg',
        });
        modalRef.componentInstance.dischargeDetail = {
          ...res,
          bindingId: this.debt.BindingId,
        };
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  generateLastBill() {
    this.isLoading = true;
    this.arrangementService
      .generateBill(this.debt.ArrangementId, {
        PortfolioType: 'PURCHASED_WALLETS',
      })
      .toPromise()
      .then((res: any) => {
        this.googleAnalyticsService.sendClickEvent(
          'payment',
          'btn_download_bankslip'
        );

        let today = new Date();
        let downloadURL = window.URL.createObjectURL(res);
        let link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${this.debt.ArrangementId
          }-${today.toLocaleDateString()}.pdf`;
        link.click();
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  showQRCode(show: boolean) {

    if (show) {
      this.showBackCard.emit(this.debt.ArrangementId);
      this.googleAnalyticsService.sendClickEvent(
        'payment',
        'btn_check_qrcode'
      );
    }

    if (!this.debt.QRCode) {
      this.isLoading = true;
      this.arrangementService
        .generatePix(this.debt.ArrangementId, {
          InstallmentID: this.debt.NextInstallmentID,
        })
        .toPromise()
        .then((res: any) => {
          this.debt.BilletLine = res.BilletLine;
          this.debt.QRCode = res.QrCode;
          this.isLoading = false;
          this.isQRCodeVisible = show;
        })
        .catch(() => {
          this.isLoading = false;
          this.isQRCodeVisible = false;
        });
    } else {
      this.isQRCodeVisible = show;
    }
  }

  checkPayment() {
    if (!this.modalPaymentOpened) {
      this.arrangementService
        .checkPaymentPix(this.debt.NextInstallmentID)
        .toPromise()
        .then((res: any) => {
          if (res.installmentID && (res.status == PaymentStatusTypes.PAID || res.status == PaymentStatusTypes.PARCIAL_PAYD)) {
            this.showModalConfirmationPixResponse(res.status);
          }
        });
    }
  }

  showModalConfirmationPixResponse(status: number) {
    let modalRefConfirm = this.modalService.open(
      ConfirmPixModalComponent,
      { centered: true, backdrop: 'static', keyboard: false }
    );

    this.modalPaymentOpened = true;
    modalRefConfirm.componentInstance.status = status;

    modalRefConfirm.result.then((ret) => {
      this.modalPaymentOpened = false;
      window.location.reload();
    });
  }

  copyToClipboard(billetLine, paymentMethod) {
    if (paymentMethod) {
      this.googleAnalyticsService.sendClickEvent('payment', 'btn_copy_pix');
    } else {
      this.googleAnalyticsService.sendClickEvent('payment', 'btn_copy_barcode');
    }
    var textArea = document.createElement('textarea');
    document.body.appendChild(textArea);
    textArea.value = billetLine;

    navigator.clipboard.writeText(textArea.value).then(() => {
      document.body.removeChild(textArea);

      this.coppied = true;

      setTimeout(() => {
        this.coppied = false;
      }, 3000);
    });
  }
}
