<swiper [config]="config" class="card-content">
  <div class="swiper-wrapper">
    <div class="swiper-slide" *ngFor="let agreement of imgsList; let i = index">
      <div class="logo-wrapper">
        <img class="logo" width="50"
          [src]="agreement.ImageUri ? agreement.ImageUri : '../../../assets/images/error.jpg'" alt="img"
          rel="preload" />
      </div>
    </div>
  </div>
</swiper>