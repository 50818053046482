<app-loading [show]="loading"></app-loading>

<div class="modal-header">
  <h5 class="modal-title">{{ 'AGREEMENTS.PIX.TITLE' | translate }}</h5>
  <p class="modal-subtitle pt-3">{{ 'AGREEMENTS.PIX.SUBTITLE' | translate }}</p>
</div>
<div class="modal-body pt-0">
  <div class="row">
    <!-- <div class="col-12">
      <div class="pt-3 pb-2 border-top">
        <app-timer [time]="180000"></app-timer>
      </div>
    </div> -->
  </div>
  <div class="col d-flex justify-content-center">
    <img class="image-company qr-code" [src]="QrCode" alt="QRCode PIX" rel="preload" />
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <p class="value border-bottom pb-3">Valor: {{InstallmentAmount | currency: 'BRL':'R$':'1.2-2'}}</p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <button class="btn btn-secondary" (click)="copyToClipboard()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z"
            stroke="#377DFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z"
            stroke="#377DFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <ng-container *ngIf="!coppied">{{ 'AGREEMENTS.PIX.COPY' | translate }}</ng-container>
        <ng-container *ngIf="coppied">{{ 'AGREEMENTS.PIX.COPPIED' | translate }}</ng-container>
      </button>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <button class="btn btn-secondary" (click)="downloadPdf(bill.file)">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#377DFF"
            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M9 11.5098L12 14.5098L15 11.5098" stroke="#377DFF" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M12 14.5098V6.50977" stroke="#377DFF" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M6 16.5098C9.89 17.8098 14.11 17.8098 18 16.5098" stroke="#377DFF" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        {{ 'AGREEMENTS.PIX.BTN-PDF' | translate }} </button>
    </div>
  </div>
</div>