import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-details',
  templateUrl: './modal-details.component.html',
  styleUrls: ['./modal-details.component.scss']
})
export class ModalDetailsComponent implements OnInit {
  @Input() debt: any;
  @Input() delayDebt: any;

  constructor(
    private activeModal: NgbActiveModal

  ) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close('cancel');

  }


}
