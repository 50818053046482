<div class="modal-header text-center">
    <div class="header-container">
        <img src="../../../../assets/images/Group 1.png" alt="Ícone de documento" class="icon-top">
        <p class="header-text">Seus acordos estão em andamento!</p>
    </div>
</div>

<div class="modal-body text-center">
    <p class="description">
        Você possui {{unified.length}} acordos em andamento. Confira abaixo algumas informações importantes.
    </p>
    <div class="agreement-cards">
        <div class="cards">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 290 60" class="agreement-card responsive-svg"
                *ngFor="let agreement of unified">
                <path
                    d="M288.306 0.412927C287.816 0.141806 287.264 -0.000260692 286.704 7.52204e-07H115.25C114.563 0.00132483 113.893 0.21697 113.334 0.616978C112.775 1.01699 112.354 1.58149 112.13 2.23206C111.668 3.56069 110.805 4.71248 109.66 5.52755C108.515 6.34263 107.146 6.78055 105.741 6.78055C104.336 6.78055 102.967 6.34263 101.822 5.52755C100.677 4.71248 99.8141 3.56069 99.3526 2.23206C99.1285 1.58149 98.7076 1.01699 98.1485 0.616978C97.5893 0.21697 96.9196 0.00132483 96.2325 7.52204e-07H3.30958C2.87506 -0.000292493 2.44475 0.0851578 2.04322 0.251489C1.6417 0.41782 1.27684 0.661763 0.969487 0.969375C0.662135 1.27699 0.418319 1.64224 0.25197 2.04426C0.085621 2.44628 -9.86521e-08 2.8772 0 3.31237V57.6876C-9.86521e-08 58.1228 0.085621 58.5537 0.25197 58.9557C0.418319 59.3577 0.662135 59.723 0.969487 60.0306C1.27684 60.3382 1.6417 60.5822 2.04322 60.7485C2.44475 60.9148 2.87506 61.0003 3.30958 61H96.2213C96.9085 60.9987 97.5782 60.783 98.1373 60.383C98.6965 59.983 99.1174 59.4185 99.3415 58.7679C99.803 57.4393 100.666 56.2875 101.811 55.4724C102.956 54.6574 104.325 54.2194 105.73 54.2194C107.135 54.2194 108.504 54.6574 109.649 55.4724C110.794 56.2875 111.657 57.4393 112.118 58.7679C112.343 59.4185 112.763 59.983 113.323 60.383C113.882 60.783 114.551 60.9987 115.239 61H286.693C287.127 61.0003 287.557 60.9148 287.959 60.7485C288.36 60.5821 288.725 60.3381 289.032 60.0305C289.339 59.7228 289.583 59.3576 289.749 58.9556C289.915 58.5536 290 58.1227 290 57.6876V3.31237C290.001 2.72178 289.845 2.1416 289.547 1.63184C289.249 1.12208 288.821 0.701263 288.306 0.412927Z"
                    fill="url(#paint0_linear_8076_5601)" />

                <!-- Linha pontilhada centralizada no entalhe -->
                <line x1="105" y1="5" x2="105" y2="75" stroke="#007bff" stroke-width="2" stroke-dasharray="4 4" />
                <!-- Adicionando o texto "Acordo nº" -->
                <text class="font-title" x="20" y="25" fill="#333" font-family="IBM Plex Sans">Acordo nº</text>
                <!-- Adicionando o número do acordo -->
                <text class="font-data" x="20" y="45" fill="#333" font-family="IBM Plex Sans" font-weight="bold">
                    {{agreement.ArrangementID}}
                </text>
                <!-- Adicionando o texto "Data de vencimento" -->
                <text class="font-title" x="200" y="25" fill="#333" font-family="IBM Plex Sans" text-anchor="middle">
                    Data de vencimento
                </text>
                <!-- Adicionando a data -->
                <text class="font-data" x="200" y="45" fill="#333" font-family="IBM Plex Sans" font-weight="bold"
                    text-anchor="middle">
                    {{agreement.NextInstallmentDate | date:'dd/MM/yyyy'}}
                </text>
                <defs>
                    <linearGradient id="paint0_linear_8076_5601" x1="216.934" y1="16.2405" x2="-223.393" y2="144.158"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color="#CECECE" />
                    </linearGradient>
                </defs>
            </svg>

        </div>
    </div>

    <div class="how-to">
        No momento, não é possível gerar o boleto por aqui. Procure um dos canais abaixo para solicitar a 2ª via e
        continuar a efetivação do seu acordo.
    </div>


    <div class="contact-info">
        <div class="contact-item">
            <div class="icon">
                <img src="../../../../assets/images/whatsapp-popup.png" alt="WhatsApp Icon" />
            </div>
            <div class="details">
                <p>Whatsapp</p>
                <strong>0800 025 0000</strong>
            </div>
            <div class="actions">
                <img src="../../../../assets/images/copy.png" alt="Copy Icon"
                    (click)="copyToClipboard('0800 025 0000')" />
                <a href="https://api.whatsapp.com/send?phone=5508000250000" target="_blank">
                    <img src="../../../../assets/images/export.png" alt="External Link Icon" />
                </a>
            </div>
        </div>
        <div class="contact-item">
            <div class="icon">
                <img src="../../../../assets/images/call-popup.png" alt="Phone Icon" />
            </div>
            <div class="details">
                <p>Telefone</p>
                <strong>0800 025 0000</strong>
            </div>
            <div class="actions">
                <img src="../../../../assets/images/copy.png" alt="Copy Icon"
                    (click)="copyToClipboard('0800 025 0000')" />
            </div>
        </div>
    </div>

    <div class="modal-footer text-center">
        <button type="button" class="btn btn-primary w-100" (click)="close()">Entendi</button>
    </div>