<span class="text-timer">
  <!-- <i class="fa fa-info-circle" placement="auto"
  ngbTooltip="Você poderá solicitar o QR Code novamente e pagá-lo até o vencimento desta parcela."
  #tooltip="ngbTooltip" (click)="tooltip.open()"></i>  -->
  Tempo
  restante: <strong>{{currentTime |
    date:'mm:ss'}}</strong></span>

<div class="wrapper">
  <div class="progress-bar">
    <span class="progress-bar-fill" [style.width.%]="percentTime"></span>
  </div>
</div>