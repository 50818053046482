import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentStatusTypes } from '../../models/paymentConfirmation.model';

@Component({
  selector: 'app-confirm-payment-modal',
  templateUrl: './confirm-payment-modal.component.html',
  styleUrls: ['./confirm-payment-modal.component.scss']
})
export class ConfirmPixModalComponent implements OnInit {

  status: number;
  paymentStatusTypes = PaymentStatusTypes;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close('close');
  }

}



