import { Component, Input, OnInit } from '@angular/core';
import { Autoplay, SwiperOptions } from 'swiper';
@Component({
  selector: 'app-images-carousel',
  templateUrl: './images-carousel.component.html',
  styleUrls: ['./images-carousel.component.scss']
})
export class ImagesCarouselComponent implements OnInit {
  @Input() imgsList: any[];

  config: SwiperOptions = {
    slidesPerView: 1.1,
    autoplay: {
      disableOnInteraction: false,
      delay: 5000,
    },
    spaceBetween: 30,
  }

  constructor() { }

  ngOnInit(): void {

  }


}
