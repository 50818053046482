<app-loading [show]="loading"></app-loading>
<div class="auth-content">
  <div class="header d-flex align-items-center justify-content-between">
    <a href="/">
      <img src="./assets/images/logo.svg" class="center" alt="logo Ipanema" rel="preload" />
    </a>

  </div>
  <div class="row">
    <div class="col-7 hide-if-mobile"> <img src="./assets/images/login.png" class="main-image" alt="logo Ipanema"
        rel="preload" />
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 col-xl-5 login">
      <div class="login-area p-5">
        <div class="row justify-content-center">
          <div class="title-col col-10 col-sm-8 col-lg-10 col-xl-10 align-items-center">
            <h3 class="title" *ngIf="title">{{ title }}</h3>
            <p class="subtitle" *ngIf="subtitle">{{ subtitle }}</p>
          </div>
        </div>

        <ng-content></ng-content>

        <div class="row justify-content-center">
          <div class=" p-2"><a href="https://www.facebook.com/negocie.ipanema" target="_blank"><img
                src="./assets/images/facebook.png" class="social" alt="Facebook" rel="preload" /></a>
          </div>
          <div class=" p-2"> <a href="https://www.youtube.com/@negocieipanema9355" target="_blank"><img
                src="./assets/images/youtube.png" class="social" alt="Youtube" rel="preload" /></a></div>
          <div class=" p-2"> <a href="https://www.instagram.com/negocieipanema/" target="_blank"> <img
                src="./assets/images/instagram.png" class="social" alt="Instagram" rel="preload" /></a>
          </div>
        </div>
      </div>

    </div>


  </div>