<swiper [config]="configCompanies">
  <div class="swiper-wrapper swiper-image banner-height">

    <div class="swiper-slide">

      <div class="card__image pic-large"><object data="assets/images/banners/Banner Web Negociação EL.png" alt=""
          type="image/png"></object>
        <div class="row container-actions">
          <div class="container-title container-img col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="home-content">
              <div class="title">
                <div class="btn-actions mobile m-0 justify-content-center resize-title">
                  <div class="btn-size row">
                    <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <button type="button" class="button primary home-debt-button btnmobile banner-desconto"
                        id="Negociar minhas dívidas" (click)="redirectSignUp()" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1"
                        data-evt-context-id="Negociar minhas dívidas" data-evt-element-id="Negociar minhas dívidas">
                        Simule já o seu acordo
                      </button>
                    </div>
                    <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <button type="button" class="button primary home-bt-boleto btnmobile banner-desconto"
                        id="2a via de boleto" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" data-evt-context-id="segunda_via"
                        data-evt-element-id="2a via de boleto" (click)="segundaViaBoleto()">
                        2ª via para pagamento
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card__image pic-medium"><object data="assets/images/banners/Banner medium Negociação M.png" alt=""
          type="image/png"></object>
        <div class="row container-actions">
          <div class="container-title container-img col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="home-content">
              <div class="title">
                <div class="btn-actions mobile m-0 justify-content-center resize-title">
                  <div class="btn-size row">
                    <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <button type="button" class="button primary home-debt-button btnmobile banner-desconto"
                        id="Negociar minhas dívidas" (click)="redirectSignUp()" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1"
                        data-evt-context-id="Negociar minhas dívidas" data-evt-element-id="Negociar minhas dívidas">
                        Simule já o seu acordo
                      </button>
                    </div>
                    <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <button type="button" style="margin-top: 10px;"
                        class="button primary home-bt-boleto btnmobile banner-desconto" id="2a via de boleto"
                        data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" data-evt-context-id="segunda_via"
                        data-evt-element-id="2a via de boleto" (click)="segundaViaBoleto()">
                        2ª via para pagamento
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card__image pic-small"><object data="assets/images/banners/Banner Mobile Negociação Mob.png" alt=""
          type="image/png"></object>
        <div class="row container-actions">
          <div class="container-title container-img col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="home-content">
              <div class="title">
                <div class="btn-actions mobile m-0 justify-content-center resize-title">
                  <div class="btn-size">
                    <div>
                      <button type="button" class="button primary home-debt-button btnmobile banner-desconto"
                        id="Negociar minhas dívidas" (click)="redirectSignUp()" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1"
                        data-evt-context-id="Negociar minhas dívidas" data-evt-element-id="Negociar minhas dívidas">
                        Simule já o seu acordo
                      </button>
                    </div>
                    <div>
                      <button style="margin-top: 10px;" type="button"
                        class="button primary home-bt-boleto btnmobile banner-desconto" id="2a via de boleto"
                        data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" data-evt-context-id="segunda_via"
                        data-evt-element-id="2a via de boleto" (click)="segundaViaBoleto()">
                        2ª via para pagamento
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div class="swiper-slide">
      <div class="card__image pic-large"><object data="assets/images/banners/Banner Web 3840px x 1368px.png" alt=""
          type="image/png"></object>
        <div class="row container-actions">
          <div class="container-title container-img col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="home-content">
              <div class="title">
                <div class="btn-actions mobile m-0 justify-content-center resize-title">
                  <div class="btn-size row">
                    <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <button type="button" class="button primary home-debt-button btnmobile banner-desconto"
                        id="Negociar minhas dívidas" (click)="redirectSignUp()" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1"
                        data-evt-context-id="Negociar minhas dívidas" data-evt-element-id="Negociar minhas dívidas">
                        Simule já o seu acordo
                      </button>
                    </div>
                    <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <button type="button" class="button primary home-bt-boleto btnmobile banner-desconto"
                        id="2a via de boleto" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" data-evt-context-id="segunda_via"
                        data-evt-element-id="2a via de boleto" (click)="segundaViaBoleto()">
                        2ª via para pagamento
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card__image pic-medium"><object data="assets/images/banners/Banner medium medium 2500px x 2100px.png"
          alt="" type="image/png"></object>
        <div class="row container-actions">
          <div class="container-title container-img col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="home-content">
              <div class="title">
                <div class="btn-actions mobile m-0 justify-content-center resize-title">
                  <div class="btn-size row">
                    <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <button type="button" class="button primary home-debt-button btnmobile banner-desconto"
                        id="Negociar minhas dívidas" (click)="redirectSignUp()" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1"
                        data-evt-context-id="Negociar minhas dívidas" data-evt-element-id="Negociar minhas dívidas">
                        Simule já o seu acordo
                      </button>
                    </div>
                    <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <button type="button" style="margin-top: 10px;"
                        class="button primary home-bt-boleto btnmobile banner-desconto" id="2a via de boleto"
                        data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" data-evt-context-id="segunda_via"
                        data-evt-element-id="2a via de boleto" (click)="segundaViaBoleto()">
                        2ª via para pagamento
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card__image pic-small"><object data="assets/images/banners/Mobile - 1125x2118 - V2.png" alt=""
          type="image/png"></object>
        <div class="row container-actions">
          <div class="container-title container-img col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="home-content">
              <div class="title">
                <div class="btn-actions mobile m-0 justify-content-center resize-title">
                  <div class="btn-size">
                    <div>
                      <button type="button" class="button primary home-debt-button btnmobile banner-desconto"
                        id="Negociar minhas dívidas" (click)="redirectSignUp()" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1"
                        data-evt-context-id="Negociar minhas dívidas" data-evt-element-id="Negociar minhas dívidas">
                        Simule já o seu acordo
                      </button>
                    </div>
                    <div>
                      <button style="margin-top: 10px;" type="button"
                        class="button primary home-bt-boleto btnmobile banner-desconto" id="2a via de boleto"
                        data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" data-evt-context-id="segunda_via"
                        data-evt-element-id="2a via de boleto" (click)="segundaViaBoleto()">
                        2ª via para pagamento
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- inicio banner desenrola -->
    <!-- <div class="swiper-slide ">
      <div class="card__image pic-large"><object data="assets/images/banners/Banner Dinheiro L.png" alt=""
          type="image/png"></object>
        <div class="row container-actions">
          <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="home-content">
              <div class="title">
                <div class="btn-actions mobile m-0 justify-content-center resize-title">
                  <div class="btn-size row">
                    <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <button type="button" class="button primary home-debt-button btnmobile banner-desenrola"
                        id="Negociar minhas dívidas" (click)="redirectSignUp()" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1"
                        data-evt-context-id="Negociar minhas dívidas" data-evt-element-id="Negociar minhas dívidas">
                        Simule já o seu acordo
                      </button>
                    </div>
                    <div class="container-title container-img col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <button type="button" class="button primary home-bt-boleto btnmobile banner-desenrola"
                        id="2a via de boleto" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" data-evt-context-id="segunda_via"
                        data-evt-element-id="2a via de boleto" (click)="segundaViaBoleto()">
                        2ª via para pagamento
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="card__image pic-medium"><object data="assets/images/banners/Banner Dinheiro M.png" alt=""
          type="image/png"></object>
        <div class="row container-actions">
          <div class="container-title container-img col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="home-content">
              <div class="title">
                <div class="btn-actions mobile m-0 justify-content-center resize-title">
                  <div class="btn-size row">
                    <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <button type="button" class="button primary home-debt-button btnmobile banner-desenrola"
                        id="Negociar minhas dívidas" (click)="redirectSignUp()" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1"
                        data-evt-context-id="Negociar minhas dívidas" data-evt-element-id="Negociar minhas dívidas">
                        Simule já o seu acordo
                      </button>
                    </div>
                    <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <button type="button" style="margin-top: 10px"
                        class="button primary home-bt-boleto btnmobile banner-desenrola" id="2a via de boleto"
                        data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" data-evt-context-id="segunda_via"
                        data-evt-element-id="2a via de boleto" (click)="segundaViaBoleto()">
                        2ª via para pagamento
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card__image pic-small"><object data="assets/images/banners/Banner Dinheiro Mob.png" alt=""
          type="image/png"></object>

        <div class="row container-actions">
          <div class="container-title container-img col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="home-content">
              <div class="title">
                <div class="btn-actions mobile m-0 justify-content-center resize-title">
                  <div class="btn-size">
                    <div>
                      <button type="button" class="button primary home-debt-button btnmobile banner-desenrola"
                        id="Negociar minhas dívidas" (click)="redirectSignUp()" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1"
                        data-evt-context-id="Negociar minhas dívidas" data-evt-element-id="Negociar minhas dívidas">
                        Simule já o seu acordo
                      </button>
                    </div>
                    <div>
                      <button style="margin-top: 10px;" type="button"
                        class="button primary home-bt-boleto btnmobile banner-desenrola" id="2a via de boleto"
                        data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" data-evt-context-id="segunda_via"
                        data-evt-element-id="2a via de boleto" (click)="segundaViaBoleto()">
                        2ª via para pagamento
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- fim banner desenrola -->



    <div class="swiper-slide">

      <div class="card__image pic-large"><object data="assets/images/banners/Banner Casal L.png" alt=""
          type="image/png"></object>
        <div class="row container-actions">
          <div class="container-title container-img col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="home-content">
              <div class="title">
                <div class="btn-actions mobile m-0 justify-content-center resize-title">
                  <div class="btn-size row">
                    <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <button type="button" class="button primary home-debt-button btnmobile banner-parcelas"
                        id="Negociar minhas dívidas" (click)="redirectSignUp()" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1"
                        data-evt-context-id="Negociar minhas dívidas" data-evt-element-id="Negociar minhas dívidas">
                        Simule já o seu acordo
                      </button>
                    </div>
                    <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <button type="button" class="button primary home-bt-boleto btnmobile banner-parcelas"
                        id="2a via de boleto" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" data-evt-context-id="segunda_via"
                        data-evt-element-id="2a via de boleto" (click)="segundaViaBoleto()">
                        2ª via para pagamento
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card__image pic-medium"><object data="assets/images/banners/Banner Casal M.png" alt=""
          type="image/png"></object>
        <div class="row container-actions">
          <div class="container-title container-img col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="home-content">
              <div class="title">
                <div class="btn-actions mobile m-0 justify-content-center resize-title">
                  <div class="btn-size row">
                    <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <button type="button" class="button primary home-debt-button btnmobile banner-parcelas"
                        id="Negociar minhas dívidas" (click)="redirectSignUp()" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1"
                        data-evt-context-id="Negociar minhas dívidas" data-evt-element-id="Negociar minhas dívidas">
                        Simule já o seu acordo
                      </button>
                    </div>
                    <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <button type="button" style="margin-top: 10px;"
                        class="button primary home-bt-boleto btnmobile banner-parcelas" id="2a via de boleto"
                        data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" data-evt-context-id="segunda_via"
                        data-evt-element-id="2a via de boleto" (click)="segundaViaBoleto()">
                        2ª via para pagamento
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card__image pic-small"><object data="assets/images/banners/Banner Casal Mob.png" alt=""
          type="image/png"></object>
        <div class="row container-actions">
          <div class="container-title container-img col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="home-content">
              <div class="title">
                <div class="btn-actions mobile m-0 justify-content-center resize-title">
                  <div class="btn-size">
                    <div>
                      <button type="button" class="button primary home-debt-button btnmobile banner-parcelas"
                        id="Negociar minhas dívidas" (click)="redirectSignUp()" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1"
                        data-evt-context-id="Negociar minhas dívidas" data-evt-element-id="Negociar minhas dívidas">
                        Simule já o seu acordo
                      </button>
                    </div>
                    <div>
                      <button style="margin-top: 10px;" type="button"
                        class="button primary home-bt-boleto btnmobile banner-parcelas" id="2a via de boleto"
                        data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" data-evt-context-id="segunda_via"
                        data-evt-element-id="2a via de boleto" (click)="segundaViaBoleto()">
                        2ª via para pagamento
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- banner pix -->
    <div class="swiper-slide">
      <div class="card__image pic-large"><object data="assets/images/banners/Banner Pix L.png" alt=""
          type="image/png"></object>
        <div class="row container-actions">
          <div class="container-title container-img col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="home-content">
              <div class="title">
                <div class="btn-actions mobile m-0 justify-content-center resize-title">
                  <div class="btn-size row">
                    <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <button type="button" class="button primary home-debt-button btnmobile banner-pix"
                        id="Negociar minhas dívidas" (click)="redirectSignUp()" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1"
                        data-evt-context-id="Negociar minhas dívidas" data-evt-element-id="Negociar minhas dívidas">
                        Simule já o seu acordo
                      </button>
                    </div>
                    <div class="container-title container-img col-sm-12 col-md126 col-lg-6 col-xl-6">
                      <button type="button" class="button primary home-bt-boleto btnmobile banner-Pix"
                        id="2a via de boleto" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" data-evt-context-id="segunda_via"
                        data-evt-element-id="2a via de boleto" (click)="segundaViaBoleto()">
                        2ª via para pagamento
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card__image pic-medium"><object data="assets/images/banners/Banner Pix M.png" alt=""
          type="image/png"></object>
        <div class="row container-actions">
          <div class="container-title container-img col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="home-content">
              <div class="title">
                <div class="btn-actions mobile m-0 justify-content-center resize-title">
                  <div class="btn-size row">
                    <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <button type="button" class="button primary home-debt-button btnmobile banner-Pix"
                        id="Negociar minhas dívidas" (click)="redirectSignUp()" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1"
                        data-evt-context-id="Negociar minhas dívidas" data-evt-element-id="Negociar minhas dívidas">
                        Simule já o seu acordo
                      </button>
                    </div>
                    <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <button type="button" style="margin-top: 10px;"
                        class="button primary home-bt-boleto btnmobile banner-Pix" id="2a via de boleto"
                        data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" data-evt-context-id="segunda_via"
                        data-evt-element-id="2a via de boleto" (click)="segundaViaBoleto()">
                        2ª via para pagamento
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card__image pic-small"><object data="assets/images/banners/Banner Pix Mob.png" alt=""
          type="image/png"></object>
        <div class="row container-actions">
          <div class="container-title container-img col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="home-content">
              <div class="title">
                <div class="btn-actions mobile m-0 justify-content-center resize-title">
                  <div class="btn-size">
                    <div>
                      <button type="button" class="button primary home-debt-button btnmobile banner-PIx"
                        id="Negociar minhas dívidas" (click)="redirectSignUp()" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1"
                        data-evt-context-id="Negociar minhas dívidas" data-evt-element-id="Negociar minhas dívidas">
                        Simule já o seu acordo
                      </button>
                    </div>
                    <div>
                      <button style="margin-top: 10px;" type="button"
                        class="button primary home-bt-boleto btnmobile banner-PIx" id="2a via de boleto"
                        data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" data-evt-context-id="segunda_via"
                        data-evt-element-id="2a via de boleto" (click)="segundaViaBoleto()">
                        2ª via para pagamento
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="swiper-pagination"></div>
</swiper>