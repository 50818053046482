import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ArrangementService } from 'src/app/services/arrangement.service';

@Component({
  selector: 'app-billet-modal',
  templateUrl: './billet-modal.component.html',
  styleUrls: ['./billet-modal.component.scss']
})
export class BilletModalComponent implements OnInit {

  @Input() ArrangementID: number;
  @Input() InstallmentID: number;
  @Input() InstallmentAmount: number;
  @Input() BilletLine: any;
  loading: boolean = false;
  coppied = false;
  bill: any;

  constructor(
    private activeModal: NgbActiveModal,
    private arrangementService: ArrangementService
  ) { }

  ngOnInit(): void {
    this.generateLastBill()
  }

  generateLastBill() {
    this.loading = true;
    this.arrangementService
      .generateBillUnified
      ({
        arrangementID: this.ArrangementID,
        installmentID: this.InstallmentID
      })
      .toPromise()
      .then((res: any) => {
        this.bill = res.Data.paymentIntent
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
        this.close();
      });
  }

  downloadPdf(base64String: string) {
    let today = new Date();
    let fileName = `${this.ArrangementID}-${today.toLocaleDateString()}.pdf`
    // Decodifica a string base64
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  copyToClipboard() {
    var textArea = document.createElement('textarea');
    document.body.appendChild(textArea);
    textArea.value = this.BilletLine;

    this.coppied = true;

    navigator.clipboard.writeText(textArea.value).then(() => {
      document.body.removeChild(textArea);
      setTimeout(() => {
        this.coppied = false;
      }, 3000);
    });
  }

  close() {
    this.activeModal.close('cancel');
  }


}
