import { Component, OnInit, ViewChild, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DebtService } from 'src/app/services/debt.service';
import { CartOffersComponent } from '../cart-offers/cart-offers.component';
import { NgbAccordion, NgbModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @ViewChild(CartOffersComponent) cartComponent: CartOffersComponent;
  @ViewChild('accordion') accordion: NgbAccordion;

  loading: boolean;

  paymentMethods: any; // list of paymentMethods
  paymentDetail: any; // details of payment
  paymentDebts: any; // list of debts
  paymentMethod: string = null; // selected payment method
  teste: any

  coupon: string; // discount coupon
  paymentMethodInput: string; // get input text of payment method

  //get input text of installment
  private _selectedOfferDescription: string = '';
  installments: any;
  installmentPercent: any;
  public get selectedOfferDescription(): string {
    return this._selectedOfferDescription;
  }
  public set selectedOfferDescription(v: string) {
    this._selectedOfferDescription = v;
  }

  selectedOfferID: number = null; //get the ID of installment/offer


  constructor(
    private debtService: DebtService,
    public activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {

    this.getPaymentMethods();
    this.paymentDetail = JSON.parse(localStorage.getItem('payment'));
    this.installments = this.paymentDetail.NegotiationOffers
    this.paymentDebts = JSON.parse(localStorage.getItem('debts'));

    if (this.paymentDebts.length > 1) {
      this.paymentMethod = '3'
    }

    document.getElementById("lockButton").addEventListener("click", function () {
      const messageBox = document.getElementById("messageBox");
      messageBox.classList.toggle("show-message");
    });




    //altera a porcentagem do texto do combo
    this.installments.forEach(item => {
      item.discountNumber = item.OfferDiscount.split(',')[0]; // Adiciona o número do desconto ao objeto
    });

  }

  updatePaymentDetail() {
    this.paymentDetail = JSON.parse(localStorage.getItem('payment'));
    this.selectedOfferDescription = ''

  }


  getMethodInput(methodAlias: string) {
    this.paymentMethodInput = methodAlias

  }

  saveSelectedValue(event: any) {
    const selectedOption = this.paymentDetail.NegotiationOffers.find(option => option.OfferDescription === this.selectedOfferDescription);
    if (selectedOption) {
      this.selectedOfferID = selectedOption.OfferID;
      this.cartComponent.totalValue = selectedOption.ArrangementAmount

    }
  }


  // confirm agreement on mobile version
  openModal(content) {
    this.modalService.open(content, { centered: true, size: 'md' });
  }

  // finish agreement on mobile version
  closeAgreement() {
    this.cartComponent.closeAgreement()
  }

  //get the paymentMethods
  getPaymentMethods() {
    this.loading = true
    this.debtService.getPaymentMethods()
      .subscribe(
        (ret) => {
          this.paymentMethods = ret
          this.loading = false
        }
      )
  }

  // open the next panel dynamically
  onPanelChange(event: NgbPanelChangeEvent) {
    if (event.nextState === false) {
      this.setNextPanelActive(event.panelId);
    }
  }

  setNextPanelActive(currentPanelId: string) {
    const panels = ['panel1', 'panel2', 'panel3']; // Substitua com os IDs de seus painéis
    const currentIndex = panels.findIndex(panelId => panelId === currentPanelId);
    if (currentIndex !== -1 && currentIndex < panels.length - 1) {
      const nextPanelId = panels[currentIndex + 1];
      setTimeout(() => {
        this.accordion.activeIds = nextPanelId;
      });
    }
  }
}
