<div (click)="onCopy()">
  <p class="label-text m-0 pt-2">
    <span *ngIf="!coppied" class="clipboard">
      <img src="/assets/images/copy-bold.png" alt="clipboard" class="clipboard-icon" rel="preload" />
      <p id="btn_copy_barcode" data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" attr.data-evt-context-id="{{ customerInfo }}"
        data-evt-element-id="Copiar código boleto" class="clipboard-label">
        {{ 'AGREEMENTS.AGREEMENTS.BILLET-CODE' | translate }}
      </p>
    </span>
    <span *ngIf="coppied" class="clipboard">
      <img src="/assets/images/copy-bold.png" alt="clipboard" class="clipboard-icon" rel="preload" />
      <p id="btn_copy_barcode" data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" attr.data-evt-context-id="{{ customerInfo }}"
        data-evt-element-id="Copiar código boleto" class="clipboard-label">
        {{ 'AGREEMENTS.AGREEMENTS.COPPIED' | translate }}
      </p>
    </span>

  </p>
</div>