import { ApoloService } from 'src/app/services/apolo.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  HostListener,
} from '@angular/core';
import { Debt } from '../../models/debt.model';
import { Negotiation } from '../../models/negotiation.model';
import { DebtService } from '../../../services/debt.service';
import { ToastService } from '../../../services/toast.service';
import {
  NgbCalendar,
  NgbDate,
  NgbDatepickerConfig,
  NgbDateStruct,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { AcceptProposalComponent } from '../accept-proposal/accept-proposal.component';
import { ConfirmAcceptProposalComponent } from '../confirm-accept-proposal/confirm-accept-proposal.component';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ArrangementService } from 'src/app/services/arrangement.service';
import { PurchaseGA } from '../../models/purchase-event.model';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../../../services/authentication.service';
import { PaymentMethod } from '../../models/payment-method.constant';
import { DebtComponent } from 'src/app/logged/debt/debt.component';

@Component({
  selector: 'app-debt-proposal',
  templateUrl: './debt-proposal.component.html',
  styleUrls: ['./debt-proposal.component.scss'],
})
export class DebtProposalComponent implements OnInit {
  @Input() public debt: Debt;
  @Input() public paymentMethod: PaymentMethod;
  @Output() public makeYourProposal = new EventEmitter<boolean>();
  @Input() public couponName;
  dateFormat: any;

  installmentNegotiation: Negotiation;
  negotiations: Negotiation[];
  endDateInCash: string;
  endDate: string;
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  markDisabled: any;
  isDisabled: any;
  loading = false;
  model: NgbDateStruct;
  finalArrangementId: any;
  customerInfo: any = {};
  formSubmitted: boolean = false;
  typeSubmitted: number = 0;
  @Output() public isSubmitted = new EventEmitter<boolean>();
  datepick: any;
  date: { day: string; month: string; year: string };
  dateToday: string;
  couponId;

  constructor(
    public apoloService: ApoloService,
    public authenticationService: AuthenticationService,
    private debtService: DebtService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private router: Router,
    private config: NgbDatepickerConfig,
    private calendar: NgbCalendar,
    private arrangementService: ArrangementService,
    private AnalyticsService: AnalyticsService,
    private gtmService: GoogleTagManagerService,
    private couponComp: DebtComponent
  ) {
    this.markDisabled = (date: NgbDate) => calendar.getWeekday(date) >= 6;
    this.config.firstDayOfWeek = 0;
    this.config.navigation = 'arrows';
    // this.config.outsideDays = 'collapsed';

    const current = moment();
    // If Sunday +0 days, If Saturday +1 days, Else +2 days (sum amount of weekenddays)
    const weekendsMath =
      current.weekday() == 7 ? 0 : current.weekday() == 6 ? 1 : 2;

    const maxDateProposal = current
      .clone()
      .add(environment.DiasLimitePagamento + weekendsMath, 'days');

    this.minDate = {
      year: Number(current.format('YYYY')),
      month: Number(current.format('M')),
      day: Number(current.format('D')),
    };

    this.maxDate = {
      year: Number(maxDateProposal.format('YYYY')),
      month: Number(maxDateProposal.format('M')),
      day: Number(maxDateProposal.format('D')),
    };
  }

  ngOnInit(): void {
    this.authenticationService.getCustomerInfo().then((rtn) => {
      this.customerInfo = rtn;
    });

    this.date = {
      day: moment().format('D'),
      month: moment().format('MM'),
      year: moment().format('YYYY'),
    };
    this.dateToday = this.customDateAdapter(this.date);
    this.dateToday = this.dateToday.replace('///g', '-');

    this.updateSelectedDate(this.date);
  }

  getNegotiations(date) {
    this.loading = true;
    if (this.debt) {
      this.debtService
        .NegotiationOffersCoupon(
          this.debt.BindingId,
          this.couponComp.couponResult != null
            ? this.couponComp.couponResult
            : 0,
          date
        )
        .subscribe(
          (ret) => {
            this.negotiations = ret.data.negotiationOffersCouponsResults;
            if (this.negotiations.length < 1) {
              this.makeProposal();
            }

            this.installmentNegotiation = this.negotiations[0];
            this.loading = false;
          },
          (err) => {
            this.loading = false;
          }
        );
    }
  }
  /* in case of reuse*/
  // getNegotiations(date) {
  //   this.loading = true;
  //   if (this.debt) {
  //     this.debtService
  //       .NegotiationOffersCoupon(
  //         this.debt.BindingId,
  //         this.couponComp.couponResult != null
  //           ? this.couponComp.couponResult
  //           : 0,
  //         date
  //       )
  //       .subscribe(
  //         (ret) => {
  //           if (ret.succeeded) {
  //             this.negotiations = ret.data.negotiationOffersCouponsResults;
  // 
  //             // if (this.negotiations.length < 1) {
  //             //   this.makeProposal();
  //             // }
  //             this.installmentNegotiation = this.negotiations[1];
  //             this.loading = false;
  //           } else if (!ret.succeeded) {
  //             this.loading = false;
  //             this.toastService.show({
  //               body: 'Não foi possível carregar as informações.',
  //               class: 'bg-danger',
  //             });
  //           }
  //         },
  //         (err) => {
  //           this.loading = false;
  //         }
  //       );
  //   }
  // }

  updateSelectedDate(ev) {
    let dateSelected = this.customDateAdapter(ev);
    this.datepick = dateSelected;
    this.getNegotiations(dateSelected);
    this.endDateInCash = ev;
    this.endDate = ev;
  }

  customDateAdapter = (value) => {
    return (
      value.year +
      '/' +
      ('0' + value.month).slice(-2) +
      '/' +
      ('0' + value.day).slice(-2)
    );
  };

  accept(negotiation, date, type: number) {
    this.formSubmitted = true;
    this.isSubmitted.emit(true);
    this.typeSubmitted = type;

    // if ((type == 1 && !this.endDateInCash) || (type == 2 && !this.endDate)) {
    //     this.toastService.show({
    //       body: 'Por favor, preencha a data de vencimento',
    //       class: 'bg-danger',
    //     });
    //   } else

    if (!this.paymentMethod || Number(this.paymentMethod) == 0) {
      document.getElementById('paymethod').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });

      this.toastService.show({
        body: 'Por favor, selecione uma forma de pagamento',
        class: 'bg-danger',
      });
    } else {
      let modalRef = this.modalService.open(AcceptProposalComponent, {
        centered: true,
        backdrop: true,
      });
      date = this.customDateAdapter(date);
      modalRef.componentInstance.negotiation = negotiation;
      modalRef.componentInstance.date = date;
      modalRef.componentInstance.currentBalance = this.debt.CurrentBalance;
      modalRef.componentInstance.paymentMethod = this.paymentMethod;
      modalRef.componentInstance.couponName = this.couponName;
      modalRef.componentInstance.proposal = {
        Portfolio: `${this.debt?.PortfolioName}-${this.debt?.PortfolioId}`,
      };

      modalRef.result.then((result) => {
        if (result == 'confirm') {
          const data = {
            CampaingId: negotiation.campaignId,
            FirstInstallmentDate: moment(date),
            PaymentMethod: Number(this.paymentMethod),
            CouponId:
              this.couponComp.couponResult != null
                ? this.couponComp.couponResult
                : 0,
          };
          this.loading = true;
          this.debtService
            .acceptDebt(
              this.debt.BindingId,
              negotiation.negotiationOfferId,
              data
            )
            .subscribe(
              (rtn: any) => {
                this.loading = false;
                this.getArrangement(rtn.data.ArrangementId);
                this.finalArrangementId = rtn.data.ArrangementId;
              },
              () => {
                this.loading = false;
              }
            );
        }
      });
    }
  }

  makeProposal() {
    this.makeYourProposal.emit(true);

    const gtmTag = {
      event: 'eventGA',
      category: 'portal:dividas',
      action: 'clicou',
      label: 'botao:faca-proposta',
    };
    this.gtmService.pushTag(gtmTag);
  }

  getArrangement(id) {
    this.loading = true;
    this.arrangementService.getArrangementById(id).subscribe((res) => {
      this.AnalyticsService.sendPurchaseAnalytics(res, PurchaseGA.Fixed);
      this.loading = false;
      this.router.navigate([`/painel/divida/acordo-concluido/sugestao`]);
    });
  }

  // A vista
  public aVistaInputGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:dividas',
      action: 'clicou',
      label: 'select:data-vencimento-a-vista',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public aceitarVistaBtnGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:dividas',
      action: 'clicou',
      label: 'botao:aceitar-proposta-a-vista',
    };
    this.gtmService.pushTag(gtmTag);
  }

  // Parcelado
  public parceladoInputGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:dividas',
      action: 'clicou',
      label: 'select:data-vencimento-parcelado',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public valorParcelaSelectGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:dividas',
      action: 'clicou',
      label: 'select:parcelas',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public aceitarParceladoBtnGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:dividas',
      action: 'clicou',
      label: 'botao:aceitar-proposta-parcelado',
    };
    this.gtmService.pushTag(gtmTag);
  }
}
