import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-old-auth-content',
  templateUrl: './auth-content.component.html',
  styleUrls: ['./auth-content.component.scss'],
})
export class AuthContentComponent {
  @Input() btnRollback = true;
  @Input() title: string;
  @Input() subtitle: string;

  loading = false;

  constructor(
    private location: Location,
    private route: Router,
    private gtmService: GoogleTagManagerService
  ) { }

  rollback() {
    this.loading = true;
    if (history.length > 2) {
      this.location.back();
    } else {
      this.route.navigate(['']);
    }

    const gtmTag = {
      event: 'eventGA',
      category: 'portal:entrar',
      action: 'clicou',
      label: 'botao:voltar',
    };
    this.gtmService.pushTag(gtmTag);
  }
}
