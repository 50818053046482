import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ArrangementService extends AbstractService {

  model = 'arrangements';

  getAllArrangements() {
    return this.http.get(`${environment.apiUrl}/${this.model}`);
  }

  getInstallmentInfo(id) {
    const headers = new HttpHeaders().set('X-Api-Key', environment.API_KEY)
    return this.http.get(`${environment.apiUrl}/${this.model}/${id}/arrangement-information`, { headers, observe: 'response' });

  }

  getArrangementById(id) {
    return this.http.get(`${environment.apiUrl}/${this.model}/${id}`);
  }

  getDischardById(bindingId) {
    return this.http.get(`${environment.apiUrl}/debts/${bindingId}/discharge`);
  }



  getDischardLetterFile(bindingId) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post(`${environment.apiUrl}/debts/${bindingId}/discharge`, bindingId, httpOptions);
  }

  getDischardLetterEmail(bindingId) {
    return this.http.post(`${environment.apiUrl}/debts/${bindingId}/discharge/email`, bindingId);
  }

  generateDischarge(data) {
    return this.http.post(
      `${environment.apiUrl}/debts/discharge`, data, { responseType: 'blob' }
    );
  }

  generateBill(id, data) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post(`${environment.apiUrl}/${this.model}/${id}/bill`, data, httpOptions);
  }

  generateBillUnified(data) {

    return this.http.post(`${environment.apiUrl}/${this.model}/generatepaymentintent`, data);
  }

  generateBillV2(data) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post(`${environment.apiUrl}/${this.model}/generatepaymentintent`, data, httpOptions);
  }

  generatePix(id, data) {
    return this.http.post(`${environment.apiUrl}/${this.model}/${id}/pix`, data);
  }

  checkPaymentPix(installmentId) {
    return this.http.get(`${environment.apiUrl}/${this.model}/checkpixpayment?InstallmentId=${installmentId}`);
  }

  getArrangementDebts(data) {
    return this.http.post(
      `${environment.apiUrl}/${this.model}/details-arrangement-debts`, data,
    );
  }
  checkUnified() {
    return this.http.get(`${environment.apiUrl}/${this.model}/checkarrangements`);

  }
}
