import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-copy',
    templateUrl: './copy.component.html',
    styleUrls: ['./copy.component.scss']
})
export class CopyComponent {

    coppied: boolean;

    constructor() { }

    onCopy() {
        this.coppied = true;

        setTimeout(() => {
            this.coppied = false;
        }, 3000);
    }

}
